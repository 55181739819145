.header {
    width: 100% !important;
}

.header .header-bg {
    background: url(/src/assets/images/header_md.png);
    background-color: var(--white);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 768px;
    width: 100% !important;
}

.header .header-title {
    background-color: var(--gray);
    padding: 2rem 0;
    text-align: center;
    width: 100%;
}

.header .header-title h1 {
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .header .header-title h1 {
        font-size: 48px;
    }
}
