.cookies-policy {
    font-size: 0.8rem;
    padding: 2rem 0;
    width: 100%;
}

.cookies-policy p,
.cookies-policy ul li,
.cookies-policy ol li {
    text-align: justify;
    text-justify: inter-word;
}

@media (min-width: 992px) {
    .cookies-policy {
        padding: 5rem;
    }
}
