.sectors {
  width: 100%;
  background-color: #fefefe;
  padding: 2rem 0;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.card-grid div {
  background-color: var(--white);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  height: 256px;
  margin: 0.05rem;
  padding: 1rem;
  text-align: center;
  width: 49%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-grid div:hover {
  opacity: 0.9;
}

.card-grid div span {
  background: var(--dark-50);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .card-grid div {
    width: 33.1%;
  }
}

@media (min-width: 992px) {
  .sectors {
    padding: 5rem;
  }

  .card-grid div {
    width: 24.7%;
  }
}
