@font-face {
  font-family: "Monserrat";
  src: url("/src/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "MonserratBold";
  src: url("/src/assets/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "MonserratItalic";
  src: url("/src/assets/fonts/Montserrat-Italic.ttf");
  font-style: italic;
}

:root {
  --font-family: "Monserrat", "MonserratBold", "MonserratItalic", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --white-90: rgb(255, 255, 255, 0.9);
  --white-80: rgb(255, 255, 255, 0.8);
  --purple-20: rgb(127, 0, 177, 0.2);
  --purple-50: rgb(127, 0, 177, 0.5);
  --purple-80: rgb(127, 0, 177, 0.8);
  --orange-80: rgb(255, 158, 99, 0.8);
  --orange-60: rgb(255, 158, 99, 0.6);
  --orange-50: rgb(255, 158, 99, 0.5);
  --dark-50: rgb(48, 48, 48, 0.5);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;

  --purple: #7f00b1; /* #8069ee; */
  --orange: #ff9e63;
  --pink: #ff64b4;
  --yellow: #ffd94a;
  --gray: #d9d9d9;
  --gray-light: #f0f0f0;
  --dark: #303030;
  --white: #ffffff;
}

html,
body {
  font-family: var(--font-family);
  overflow-x: hidden;

  min-width: 350px;
}