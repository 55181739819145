.section-title .title {
    color: var(--purple);
    font-size: 2rem;
    font-weight: bold;
}

.section-title hr.separator {
    border-top: 4px solid var(--orange);
    width: 100px;
}

@media (min-width: 768px) {
    .section-title .title {
        font-size: 3rem;
    }
}
