.about-us {
    width: 100%;
    background-color: var(----gray-light);
    padding: 2rem 0;
}

.about-us .subtitle {
    font-family: var(--font-family);
    line-height: 2.5rem;
    color: var(--dark);
    text-align: center;
}

@media (min-width: 768px) {
    .about-us .box-button {
        width: 80%;
    }
}

@media (min-width: 992px) {
    .about-us {
        padding: 5rem 0;
    }

    .about-us .subtitle {
        text-align: justify;
    }
}
