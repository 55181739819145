.services {
    width: 100%;
    background: url(/src/assets/images/services2.png);
    background-color: #fefefe;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 0;
}

.services .service-name {
    background-color: var(--white-90);
    border-radius: 0.25rem;
    color: var(--purple);
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
}

.services .service-name:hover {
    background-color: var(--purple-80);
    color: var(--white);
}

.services .service-detail {
    background-color: var(--white-90);
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.8rem;
    margin: 0.5rem;
    padding: 1rem;
    width: 99%;
}

.services .service-detail:hover {
    background-color: var(--white-80);
}

.services .service-detail svg {
    border-radius: 0.25rem;
    color: var(--orange);
    margin: 0.5rem;
}

@media (min-width: 992px) {
    .services {
        padding: 5rem;
    }
}
