.image-button {
    border-radius: 2.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 1.5rem;
    margin: 0.25rem;
    width: 100%;
}

.image-button Button,
.image-button a {
    border: 0;
    border-radius: 2.5rem;
    background-color: transparent !important;
    font-weight: bold;
    height: 100%;
    width: 100%;
}

.image-button a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-button Button:hover,
.image-button a:hover {
    background-color: var(--purple-20) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.image-button Button:active,
.image-button a:active {
    border: 0;
    background-color: var(--purple) !important;
}
