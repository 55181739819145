.footer {
  background-color: var(--gray);
  color: var(--orange);
  font-size: .8rem;
  padding: 1rem;
  width: 100%;
}

.footer img {
  width: 32px;
}

.footer a {
  color: var(--orange);
}
