.privacy-policy {
    font-size: 0.8rem;
    padding: 2rem 0;
    width: 100%;
}

.privacy-policy p,
.privacy-policy ul li,
.privacy-policy ol li {
    text-align: justify;
    text-justify: inter-word;
}

@media (min-width: 992px) {
    .privacy-policy {
        padding: 5rem;
    }
}
