.vacancies {
    background-color: var(--gray-light);
    color: var(--purple);
    padding: 2rem;
    width: 100%;
}

.vacancies .content {
    color: var(--purple);
    font-size: 1.5rem;
    padding-right: 4rem;
}

@media (min-width: 992px) {
    .vacancies .title {
        text-align: justify;
    }
}
