.legal-warning {
    font-size: 0.8rem;
    padding: 2rem 0;
    width: 100%;
}

.legal-warning p,
.legal-warning ul li,
.legal-warning ol li {
    text-align: justify;
    text-justify: inter-word;
}

@media (min-width: 992px) {
    .legal-warning {
        padding: 5rem;
    }
}
