.navContainer {
    height: 100px;
}

.navbar {
    border-bottom: 2px solid var(--purple);
    background-color: var(--white) !important;
}

.navbar .logo {
    width: 192px;
}

.navbar .btn-flag img {
    width: 32px;
}

.navbar .navbar-toggler {
    border: 0 !important;
}

.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28127, 0, 177, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar .icon {
    color: var(--purple-80);
    font-size: 24px;
}
