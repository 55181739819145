.card {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    min-height: 400px !important;
    width: 100% !important;
}

.card .card-img-top {
    height: 200px;
}

.card .card-body h4 {
    text-align: center;
}

.card .card-body p {
    text-align: center;
}
