.contact {
  width: 100%;
  background-color: var(--white);
  padding: 2rem 0;
}

.contact .social-media-icons img {
  width: 64px;
}

.contact .card-contact {
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 2rem;
  padding: 2rem;
}

.contact .world-image {
  max-width: 100%;
}

@media (min-width: 992px) {
  .contact {
    padding: 5rem 0;
  }
}
