.ecosystem {
  background-color: #fefefe;
  padding: 2rem 2rem 5rem 2rem;
  width: 100%;
  padding: 2rem 0;
}

.ecosystem .carousel {
  width: 100%;
}

.ecosystem .card {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
  min-height: 400px !important;
  width: 100% !important;
}

.ecosystem .card .card-img-top {
  height: 200px;
}

.ecosystem .card .card-body h4 {
  text-align: center;
}

.ecosystem .card .card-body p {
  text-align: center;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

@media (min-width: 992px) {
  .ecosystem {
    padding: 5rem 0;
  }
}
